import { useBreakpoints } from '@/hooks/useBreakpoints'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useToggle } from 'react-use'
import { Term } from '../../types'
import Tippy from '@tippyjs/react/headless'
import { useSpring, motion } from 'framer-motion'

interface ContentProps {
  content: any
  terms: Term[]
}

function convertTermType(type: string | undefined): string {
  switch (type) {
    case 'verb':
      return 'động từ'
    case 'noun':
      return 'danh từ'
    case 'adjective':
      return 'tính từ'
    case 'adverb':
      return 'trạng từ'
    default:
      return 'không xác định'
  }
}

const Content = ({ content, terms }: ContentProps) => {
  const [isTermBoxOpen, toggleTermBox] = useToggle(false)
  const [termAnchorEl, setTermAnchorEl] = useState<Element | null>(null)
  const [selectedTerm, setSelectedTerm] = useState<Term | null>(null)
  const springConfig = { damping: 15, stiffness: 300 }
  const initialScale = 0.5
  const opacity = useSpring(0, springConfig)
  const scale = useSpring(initialScale, springConfig)

  function onMount() {
    scale.set(1)
    opacity.set(1)
  }

  function onHide({ unmount }: { unmount: () => void }) {
    const cleanup = scale.onChange(value => {
      if (value <= initialScale) {
        cleanup()
        unmount()
      }
    })

    scale.set(initialScale)
    opacity.set(0)
  }

  useEffect(() => {
    document
      .querySelectorAll('a[href^="#vhl_term_"]')
      .forEach((el: Element) => {
        const href = el.getAttribute('href')?.replace('#vhl_term_', '') || ''
        // when el clicked, set referenceElement to el
        el.addEventListener('mouseenter', () => {
          setTermAnchorEl(el as HTMLAnchorElement)
          toggleTermBox(true)
          setSelectedTerm(terms.find(term => term.name === href) || null)
        })
        el.addEventListener('mouseleave', () => {
          toggleTermBox(false)
        })
      })
  }, [])
  const { isMobile } = useBreakpoints()
  const contentClass = classNames(
    'prose max-w-none tablet:border-[1px] tablet:border-gray-300 tablet:px-5 tablet:py-6',
    {
      'prose-sm': isMobile,
    }
  )
  return (
    <>
      <div
        className={contentClass}
        dangerouslySetInnerHTML={{ __html: content }}
      ></div>
      <Tippy
        reference={termAnchorEl}
        visible={isTermBoxOpen}
        onClickOutside={() => toggleTermBox(false)}
        onHide={onHide}
        onMount={onMount}
        animation={true}
        render={attr => (
          <motion.div
            {...attr}
            style={{ scale, opacity }}
            className="flex flex-col max-w-[300px] p-6 bg-white border-[1px] border-blue-500 shadow-lg lg:max-w-md"
          >
            <h4 className="text-blue-500 lg:heading-4 heading-5">
              {selectedTerm?.name}
            </h4>
            <p className="mt-1 mb-4 text-gray-400 caption-sm">
              ({convertTermType(selectedTerm?.type)})
            </p>
            <p className="font-sans text-sm font-normal text-gray-700 lg:text-base">
              {selectedTerm?.description}
            </p>
          </motion.div>
        )}
      />
    </>
  )
}

export default Content
