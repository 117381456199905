import SEO from '@/components/shared/SEO'
import loadable from '@loadable/component'
import TableOfContent from './components/TableOfContent'
import Content from './components/Content'

const Hero = loadable(() =>
  import(/* webpackPrefetch: true */ './components/Hero')
)
const Interactive = loadable(() =>
  import('./components/Interactive/SidebarInteractive')
)
const NavbarInteractive = loadable(() =>
  import('./components/Interactive/NavbarInteractive')
)
const Tags = loadable(() => import('./components/Tags'))
const SameTopic = loadable(() => import('./components/SameTopic'))
const References = loadable(() => import('./components/References'))
const NewPosts = loadable(() => import('./components/NewPosts'))

const Post = (props: any) => {
  const post = props.pageContext
  const {
    databaseId,
    title,
    content,
    tags,
    featuredImage,
    vhl_fields,
    tableOfContents,
    categories,
    modified,
    terms,
  } = post
  const writers = vhl_fields.writers
  const reviewers = vhl_fields.reviewers
  const featuredImageSource = vhl_fields.featuredImageSource
  return (
    <>
      <SEO title={post.title} image={featuredImage.node.sourceUrl} />
      <div className="base-container">
        <Hero
          title={title}
          date={modified}
          image={featuredImage.node.localFile}
          writers={writers}
          reviewers={reviewers}
          imageSource={featuredImageSource}
        />
        <div className="flex tablet:space-x-[50px] tablet:mt-10 relative">
          <div className="hidden sticky flex-shrink self-start tablet:flex top-30">
            <Interactive postId={databaseId} />
          </div>
          <div className="flex flex-col space-y-8 w-full lg:w-3/4">
            <Content content={content} terms={terms} />
            <Tags tags={tags.nodes} />
            <References references={vhl_fields.references} />
            {/* Mobile only */}
            <div className="block tablet:hidden">
              <SameTopic categories={categories.nodes} postId={databaseId} />
              <NewPosts postId={databaseId} />
            </div>
            {/* Tablet only */}
            <div className="hidden tablet:block desktop:hidden">
              <SameTopic categories={categories.nodes} postId={databaseId} />
            </div>
          </div>
          {/* Desktop only */}
          <div className="hidden sticky flex-col flex-shrink self-start space-y-8 w-1/4 lg:flex top-30">
            <div className="flex flex-col p-5 space-y-4 border-[1px] border-gray-400">
              <TableOfContent>
                <>
                  <TableOfContent.Heading />
                  <TableOfContent.Contents tableOfContents={tableOfContents} />
                </>
              </TableOfContent>
            </div>
            <SameTopic categories={categories.nodes} postId={databaseId} />
          </div>
        </div>
      </div>
      {/* Tablet & Desktop */}
      <div className="hidden tablet:block">
        <hr />
        <NewPosts postId={databaseId} />
      </div>
      {/* Mobile only */}
      <div className="block desktop:hidden">
        <NavbarInteractive
          postId={databaseId}
          tableOfContents={tableOfContents}
        />
      </div>
    </>
  )
}

export default Post
