import { ReactChild, useEffect, useState } from 'react'
import { TableOfContents } from '../types'
import { scrollTo } from '@/utils'
import classNames from 'classnames'

interface TableOfContentsProps {
  tableOfContents: TableOfContents[]
  contentBoxClass?: string
  contentTextDefault?: string
  contentTextActive?: string
}

interface Contents extends TableOfContents {
  active: boolean
}

function Heading({ className = 'heading-4' }: { className?: string }) {
  return (
    <>
      <h4 className={className}>Nội dung</h4>
      <hr style={{ borderTop: '2px solid #3163FC' }} />
    </>
  )
}

function Contents({
  tableOfContents,
  contentBoxClass = 'flex flex-col space-y-2 overflow-y-auto',
  contentTextDefault = 'text-gray-300',
  contentTextActive = 'text-blue-500',
}: TableOfContentsProps) {
  const [contents, setContents] = useState<Contents[]>(
    tableOfContents.map(content => ({ ...content, active: false }))
  )
  function activeContent(id: string) {
    setContents(
      contents.map(content => ({
        ...content,
        active: content.id === id,
      }))
    )
  }

  const tableOfContentItemClassName = (content: any) =>
    classNames(
      `transition cursor-pointer heading-6 hover:${contentTextActive}`,
      { [contentTextActive]: content.active },
      { [contentTextDefault]: !content.active },
      { 'pl-5': content.level == 3 },
      { 'pl-10': content.level == 4 },
      { 'pl-12': content.level == 5 },
      { 'pl-14': content.level == 6 }
    )

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          const id = entry.target.getAttribute('id') || ''
          if (entry.isIntersecting) {
            activeContent(id)
          }
        })
      },
      {
        root: null,
        rootMargin: '20px',
        threshold: 0.5,
      }
    )
    tableOfContents.forEach(content => {
      const element = document.getElementById(content.id)
      if (element) {
        observer.observe(element)
      }
    })
    return () => {
      observer.disconnect()
    }
  }, [])

  return (
    <div className={contentBoxClass}>
      {contents.map((content, index) => (
        <p
          className={tableOfContentItemClassName(content)}
          key={index}
          onClick={() => {
            scrollTo(content.id, 100)
            setTimeout(() => {
              activeContent(content.id)
            }, 500)
          }}
        >
          {content.text}
        </p>
      ))}
    </div>
  )
}

const TableOfContent = ({ children }: { children?: ReactChild }) => {
  return <div className="flex flex-col space-y-2 max-h-[calc(100vh-280px)] overflow-y-auto">{children}</div>
}

TableOfContent.Heading = Heading
TableOfContent.Contents = Contents

export default TableOfContent
